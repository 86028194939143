// Base Imports
import { useCallback, useEffect, useState } from 'react'

// Package Imports
import axios from '@services/axiosInterceptorInstance'
import { useRouter } from 'next/router'

// Component Imports
import { useUI } from '@components/ui/context'
import useWishlist from '@components/services/wishlist'
import cartHandler from '@components/services/cart'
import useAnalytics from '@components/services/analytics/useAnalytics'
import { EVENTS_MAP } from '@components/services/analytics/constants'
import { loginService } from 'services/login/index.service'
import Cookies from 'js-cookie'

// Other Imports
import {
  API_STATUS,
  BETTERCOMMERCE_DEFAULT_COUNTRY,
  EmptyGuid,
  LoginPageAction,
  NEXT_GOKWIK_ADDRESS_REQUEST,
  NEXT_GOKWIK_OTP_REQUEST,
  NEXT_GUEST_CHECKOUT,
  NEXT_OTP_REQUEST,
  NEXT_VALIDATE_EMAIL,
} from '@components/utils/constants'
import { NEXT_AUTHENTICATE } from '@components/utils/constants'
import {
  capitalizeWords,
  containsOnlyNumbers,
  fetchUser,
  getCurrentPage,
  resetSubmitData,
  setDefaultPaymentMethod,
  setDeliveryAddress,
  submitData,
} from '@framework/utils/app-util'
import { LocalStorage } from '@components/utils/payment-constants'
import { IExtraProps } from '@components/common/Layout/Layout'
import { setItem } from '@components/utils/localStorage'
import {
  getSessionItem,
  removeSessionItem,
  setSessionItem,
} from '@components/utils/sessionStorage'
import { GA_EVENT } from 'hooks/ga_event.modal'
import { recordGA4Event } from '@components/services/analytics/ga4'
import { asyncHandler } from './Address/helper'
import {
  INewLoginProps,
  LOGIN_FORM_ID,
  OTP_TIMER,
  getUserName,
} from './loginHelper'
import { MO_ENGAGE_EVENT } from 'hooks/moengage_event.modal'
import { useMoEngageEvent } from 'hooks/useMoengageEvent'
import LoginPromptSuccessPopup from './LoginPromptSuccessPopup'
import LoginPromptDialogBox from './LoginPromptDialogBox'
import { getDeviceTypeString } from '@commerce/utils/use-device'
import { v4 as uuid } from 'uuid'
import { getSHA256Hash } from '@framework/utils/cipher'

declare const window: any

const NewLoginModal = (props: INewLoginProps & IExtraProps) => {
  const { submitState, submitDispatch, show, close, deviceInfo, previousPath } =
    props
  const [isOTPSent, setIsOTPSent] = useState(false)
  const [mobileNumber, setMobileNumber] = useState<any>()
  const [isError, showError] = useState(false)
  const [timer, setTimer] = useState(0)
  const [newUserLoginPopup, setNewUserLoginPopup] = useState(false)

  const {
    isGuestUser,
    setIsGuestUser,
    setUser,
    user,
    wishListItems,
    setCartItems,
    setBasketId,
    setWishlist,
    cartItems,
    basketId,
    setLoginOTPCustomTitle,
    setLoginOTP,
    setLoginMobileNumber,
    appVersionData,
  } = useUI()
  const { getWishlist } = useWishlist()
  const { associateCart, getCartByUser } = cartHandler()
  const { PageViewed } = EVENTS_MAP.EVENT_TYPES
  const [validatedUserAPIStatus, setValidatedUserAPIStatus] =
    useState<API_STATUS>(API_STATUS.NOT_STARTED)
  const sessionStoredUser = getSessionItem('isUserAlreadyExist')
  const isUserAlreadyExist = sessionStoredUser
    ? JSON.parse(sessionStoredUser)
    : true
  const router = useRouter()
  const { isMobile, isIPadorTablet } = deviceInfo
  const isChannelStore =
    router?.query?.channelStore || router?.asPath?.includes('channelStore=true')
      ? true
      : false

  const getIfUserIsNewOrOld = async (_data: any) => {
    setValidatedUserAPIStatus(API_STATUS.LOADING)
    try {
      let data = null
      const response: any = isOTPSent
        ? await axios.post(NEXT_VALIDATE_EMAIL, {
            data: _data?.mobileNumber,
          })
        : await loginService?.getLoginType({
            mobileNo: _data?.mobileNumber,
          })
      if (response.data?.userType) {
        data = {
          data: response.data,
        }
      } else {
        data = response?.data
      }
      setIsOTPSent(true)
      if (_data?.mobileNumber && _data?.mobileNumber !== '') {
        setLoginMobileNumber(_data?.mobileNumber)
      }
      if (_data?.otp && _data?.otp !== '') {
        setLoginOTP(_data?.otp)
      }
      if (
        response?.data?.userType
          ? response?.data?.userType === 'BC_USER'
          : data?.length > 0
      ) {
        // Existing User Flow
        setSessionItem('isUserAlreadyExist', JSON.stringify(true))
        handleLogin(_data, data?.data)
      } else {
        // GoKwik User (New User) flow
        setSessionItem('isUserAlreadyExist', JSON.stringify(false))
        handleGoKwikLogin(_data, data?.data)
      }
    } catch (error) {
      setValidatedUserAPIStatus(API_STATUS.FAILURE)
      console.error(':: error', error)
    }
  }

  const { getAddress, createAddress } = asyncHandler()
  const trackMoEngageEvent = useMoEngageEvent()
  useAnalytics(PageViewed, {
    eventType: PageViewed,
  })

  const handleLogin = async (data: any, loginUserData: any) => {
    try {
      if (!isOTPSent) {
        resetTimer()
        submitData(submitDispatch, LoginPageAction.SEND_OTP)
        const userName = !loginUserData.userType
          ? getUserName(loginUserData?.[0])
          : loginUserData?.user?.firstName
        if (!userName || (userName && containsOnlyNumbers(userName))) {
          setLoginOTPCustomTitle(`Welcome Back!`)
        } else {
          setLoginOTPCustomTitle(`Welcome Back ${userName}!`)
        }
        // setIsOTPSent(otpResponse?.success)
        const previousPage = getCurrentPage(previousPath)
        const mobile256Sha = getSHA256Hash(data?.mobileNumber || mobileNumber);
        recordGA4Event(window, GA_EVENT.OTP_REQUEST, {
          // previous_page: previousPage,
          mobile_number: mobile256Sha,
          previous_page: 'Login Prompt',
          address_source: 'Damensch',
        })

        if (loginUserData?.userType) {
          setMobileNumber(data?.mobileNumber || mobileNumber)
          setTimer(OTP_TIMER)
        }

        resetSubmitData(submitDispatch)
        setValidatedUserAPIStatus(API_STATUS.SUCCESS)
      } else {
        submitData(submitDispatch, LoginPageAction.LOGIN)
        await handleUserLogin(data, cartItems, loginUserData)
      }
    } catch (error) {
      resetSubmitData(submitDispatch)
      console.error(':: err', error)
    }
  }

  const handleGoKwikLogin = async (data: any, loginUserData: any) => {
    try {
      if (!isOTPSent) {
        resetTimer()
        submitData(submitDispatch, LoginPageAction.SEND_OTP)

        setLoginOTPCustomTitle(`Welcome to DaMENSCH!`)
        // setIsOTPSent(otpResponse?.success)
        const previousPage = getCurrentPage(previousPath)
        const mobile256Sha = getSHA256Hash(data?.mobileNumber || mobileNumber);
        recordGA4Event(window, GA_EVENT.OTP_REQUEST, {
          // previous_page: previousPage,
          previous_page: 'Login Prompt',
          mobile_number: mobile256Sha,
          address_source: 'GoKwik',
        })
        if (loginUserData?.success) {
          setMobileNumber(data?.mobileNumber || mobileNumber)
          setTimer(OTP_TIMER)
        }
        resetSubmitData(submitDispatch)
        setValidatedUserAPIStatus(API_STATUS.SUCCESS)
      } else {
        submitData(submitDispatch, LoginPageAction.LOGIN)
        await handleGoKwikUserLogin(data, loginUserData)
      }
    } catch (error) {
      resetSubmitData(submitDispatch)
      console.error(':: err', error)
    }
  }

  const addNewAddress = async (addresses: any, user: any) => {
    const array = []
    // kept it 1 for now, in future it might change to addresses.length
    for (let i = 0; i < 1; i++) {
      const address = addresses[i]
      const newValue = {
        address1: address?.address_line1,
        address2: address?.address_line2,
        city: address?.city,
        state: address?.state,
        firstName: address?.first_name,
        lastName: address?.last_name,
        phoneNo: address?.recipient_phone,
        postCode: address?.pincode,
        isDefault: false,
        isConsentSelected: false,
        label: capitalizeWords(address?.address_type),
        title: '',
        country: address?.country,
        countryCode: address?.country,
        userId: user?.userId,
      }
      try {
        array.push(createAddress(newValue))
      } catch (error) {
        console.error('error:', error)
        throw error
      }
    }
    const response = Promise.all(array)
  }

  const registerAddresses = (addresses: Array<any>, user: any) => {
    addNewAddress(addresses, user)
  }

  const handleUserRegister = async (values: any) => {
    const user = localStorage?.getItem('user')
    const userDetails = user ? JSON.parse(user) : {}
    const response: any = await axios.post(NEXT_GUEST_CHECKOUT, {
      basketId: basketId,
      notifyByEmail: false,
      notifyBySms: true,
      notifyByPost: false,
      username: values?.mobileNumber,
      userId: userDetails?.userId,
    })
    const { data }: any = await axios.post(NEXT_VALIDATE_EMAIL, {
      data: values?.mobileNumber,
    })
    const _res = data?.[0]
    const userObj = { ..._res }
    const wishlist = await getWishlist(_res?.userId, wishListItems)
    setWishlist(wishlist)
    getWishlist(_res?.userId, wishListItems)

    const userId = _res?.userId
    const newCart = await associateCart(userId, basketId, isChannelStore)
    setCartItems(newCart?.data)
    setIsGuestUser(false)

    let cart: any = {}
    if (cartItems?.userId && cartItems?.userId === EmptyGuid) {
      cart = await associateCart(_res?.userId, cartItems?.id, isChannelStore)
    } else {
      cart = await getCartByUser({
        userId: _res?.userId,
        cart: cartItems,
        basketId,
      })
    }
    if (cart?.id) {
      setCartItems(cart)
      setBasketId(cart?.id)
      userObj.isAssociated = true
    } else {
      userObj.isAssociated = false
    }
    // const newCartClone = { ..._res, isGuestCheckout: true }
    // setCartItems(newCartClone)
    if (!userDetails?.userId) {
      setUser({
        ...userObj,
        userId: userObj.userId,
        email: userObj.userEmail,
        username: userObj.username || values?.mobileNumber,
      })
    }
    if (values?.addresses?.length > 0) {
      registerAddresses(values?.addresses, _res)
    }
  }

  const trackMoengageLoginEvent = (mobileNumber: string) => {
    if(!mobileNumber) return
    trackMoEngageEvent(
      MO_ENGAGE_EVENT.LOGIN,
      {
        URL: router?.asPath,
        device: getDeviceTypeString(),
        first_login:'',
        status: "sent",
        mobile_number: mobileNumber,
        uuid : uuid()
      },
      window
    )
  }

  const handleGoKwikUserLogin = async (values: any, loginUserData: any) => {
    setValidatedUserAPIStatus(API_STATUS.LOADING)
    try {
      const res: any = await loginService?.submitOTP({
        username: values?.mobileNumber,
        otp: parseInt(values?.otp, 10),
        userType: loginUserData?.userType || 'GOKWIK_USER',
      })
      const response = {
        data: {
          data: {
            ...res.data,
            ...(res?.data?.gokwikToken ? { otp_verified: true } : {}),
            ...(res?.data?.gokwikToken
              ? { token: res?.data?.gokwikToken }
              : {}),
            addresses: res?.data?.address,
          },
        },
      }

      if (isGuestUser) {
        if (user && !user?.userId) {
          localStorage.removeItem('user')
        }
        localStorage.removeItem(LocalStorage.Key.ORDER_RESPONSE)
        localStorage.removeItem(LocalStorage.Key.ORDER_PAYMENT)
        localStorage.removeItem(LocalStorage.Key.CONVERTED_ORDER)
        localStorage.removeItem(LocalStorage.Key.DELIVERY_ADDRESS)
        localStorage.removeItem(LocalStorage.Key.PREFERRED_PAYMENT)
      }

      if (!response?.data?.data?.otp_verified) {
        setValidatedUserAPIStatus(API_STATUS.FAILURE)
        resetSubmitData(submitDispatch)
        showError(true)
      } else if (response?.data?.data?.otp_verified) {
        setItem('goKwikAccessToken', response?.data?.data?.token)

        if (response?.data?.data?.addresses?.length > 0) {
          setSessionItem('goKwikAddresses', response?.data?.data?.addresses)
        }

        const previousPage = getCurrentPage()
        recordGA4Event(window, GA_EVENT.OTP_SUBMIT, {
          // previous_page: previousPage,
          previous_page: 'Login Prompt',
          address_source: 'GoKwik',
        })

        trackMoengageLoginEvent(values?.mobileNumber)
        const loginResult = response?.data?.data?.user
        Cookies.set(
          LocalStorage.Key.userEncryptedDetails,
          loginResult?.userToken?.access_token
        )
        localStorage.setItem(
          LocalStorage.Key.userEncryptedDetails,
          loginResult?.userToken?.access_token
        )
        setUser(loginResult)
        localStorage.setItem('user', JSON.stringify(loginResult))
        await handleUserRegister({
          mobileNumber: values?.mobileNumber,
          otp: values?.otp,
          addresses: response?.data?.data?.addresses,
        })
        setIsGuestUser(false)
        setNewUserLoginPopup(true)
        resetSubmitData(submitDispatch)
        if (window?.Moengage) {
          window?.Moengage.update_unique_user_id(values?.mobileNumber)
          window?.Moengage.add_unique_user_id(values?.mobileNumber)
          window?.Moengage.add_first_name(values?.firstName)
          window?.Moengage.add_last_name(values?.lastName)
          window?.Moengage.add_email(values?.email)
          window?.Moengage.add_mobile(values?.mobileNumber)
          window?.Moengage.add_user_name(
            `${values?.firstName} ${values?.lastName}`
          )
          window?.Moengage.add_gender(values?.gender)
          window?.Moengage.add_birthday(
            `${values?.dayOfBirth}/${values?.monthOfBirth}/${values?.yearOfBirth}`
          )
          window?.Moengage.add_user_attribute('address_source', 'Gokwik')
        }
        setSessionItem('loaderState', 'true')
        localStorage.removeItem('routeRedirectionToCart')
        removeSessionItem('loaderState')
        setValidatedUserAPIStatus(API_STATUS.SUCCESS)
        close()
        setTimeout(() => {
          setNewUserLoginPopup(true)
        }, 800)
      }
    } catch (error) {
      setValidatedUserAPIStatus(API_STATUS.FAILURE)
      console.error(':: error', error)
    }
  }

  const resendOTP = async (data: any) => {
    if (isUserAlreadyExist) {
      // normal user flow
      resetTimer()
      submitData(submitDispatch, LoginPageAction.SEND_OTP)
      const res: any = await loginService?.getLoginType({
        mobileNo: data?.mobileNumber || mobileNumber,
      })
      const otpResponse = res?.data
      setIsOTPSent(otpResponse?.success)

      if (otpResponse?.success) {
        setMobileNumber(data?.mobileNumber || mobileNumber)
      }
      resetSubmitData(submitDispatch)
    } else {
      // gokwik user flow
      resetTimer()
      submitData(submitDispatch, LoginPageAction.SEND_OTP)
      const res: any = await loginService?.getLoginType({
        mobileNo: data?.mobileNumber || mobileNumber,
      })
      const otpResponse = res?.data
      setIsOTPSent(otpResponse?.success)

      if (otpResponse?.success) {
        setMobileNumber(data?.mobileNumber || mobileNumber)
      }
      resetSubmitData(submitDispatch)
    }
  }

  const handleUserLogin = async (
    values: any,
    cartItems: any,
    loginUserData: any
  ) => {
    // If previous guest login exists
    if (isGuestUser) {
      if (user && !user?.userId) {
        localStorage.removeItem('user')
      }
      localStorage.removeItem(LocalStorage.Key.ORDER_RESPONSE)
      localStorage.removeItem(LocalStorage.Key.ORDER_PAYMENT)
      localStorage.removeItem(LocalStorage.Key.CONVERTED_ORDER)
      localStorage.removeItem(LocalStorage.Key.DELIVERY_ADDRESS)
      localStorage.removeItem(LocalStorage.Key.PREFERRED_PAYMENT)
    }

    setValidatedUserAPIStatus(API_STATUS.LOADING)
    const response: any = await loginService?.submitOTP({
      username: values?.mobileNumber,
      otp: values?.otp,
      userType: loginUserData?.userType || 'BC_USER',
    })
    const loginResult = response?.data?.user
    localStorage.setItem(
      LocalStorage.Key.userEncryptedDetails,
      loginResult?.userToken?.access_token
    )
    Cookies.set(
      LocalStorage.Key.userEncryptedDetails,
      loginResult?.userToken?.access_token
    )
    if (!loginResult || (loginResult && loginResult?.userId === EmptyGuid)) {
      setValidatedUserAPIStatus(API_STATUS.FAILURE)
      resetSubmitData(submitDispatch)
      showError(true)
    } else if (loginResult?.userId) {
      const previousPage = getCurrentPage()
      recordGA4Event(window, GA_EVENT.OTP_SUBMIT, {
        // previous_page: previousPage,
        previous_page: 'Login Prompt',
        address_source: 'Damensch',
      })
      trackMoengageLoginEvent(values?.mobileNumber)
      const userObj = { ...loginResult }
      const wishlist = await getWishlist(loginResult?.userId, wishListItems)
      setWishlist(wishlist)
      getWishlist(loginResult?.userId, wishListItems)

      const userId = loginResult?.userId
      const newCart = await associateCart(userId, basketId, isChannelStore)
      setCartItems(newCart?.data)
      setIsGuestUser(false)

      const getCustomerResult = await fetchUser(userId)
      setDefaultPaymentMethod(getCustomerResult)

      let cart: any = {}
      if (cartItems?.userId && cartItems?.userId === EmptyGuid) {
        cart = await associateCart(
          loginResult?.userId,
          cartItems?.id,
          isChannelStore
        )
      } else {
        cart = await getCartByUser({
          userId: loginResult?.userId,
          cart: cartItems,
          basketId,
        })
      }

      const addressResult: any = await getAddress(loginResult?.userId)
      const shippingAddress = addressResult.find(
        (item: any) => item?.isDefaultDelivery
      )
      if (shippingAddress) {
        setDeliveryAddress(shippingAddress)
      }
      if (cart?.id) {
        setCartItems(cart)
        setBasketId(cart?.id)
        userObj.isAssociated = true
      } else {
        userObj.isAssociated = false
      }
      setUser(userObj)
      // setAlert({ type: 'success', msg: LOGIN })
      resetSubmitData(submitDispatch)
      // set moengage user attributes
      if (window?.Moengage) {
        window?.Moengage.update_unique_user_id(values?.mobileNumber)
        window?.Moengage.add_unique_user_id(values?.mobileNumber)
        window?.Moengage.add_first_name(userObj.firstName)
        window?.Moengage.add_last_name(userObj.lastName)
        window?.Moengage.add_email(userObj.email)
        window?.Moengage.add_mobile(values?.mobileNumber)
        window?.Moengage.add_user_name(
          `${userObj.firstName} ${userObj.lastName}`
        )
        window?.Moengage.add_gender(userObj.gender)
        window?.Moengage.add_birthday(
          `${userObj.dayOfBirth}/${userObj.monthOfBirth}/${userObj.yearOfBirth}`
        )
        window?.Moengage.add_user_attribute('address_source', 'Damensch')
      }
      setSessionItem('loaderState', 'true')
      localStorage.removeItem('routeRedirectionToCart')
      removeSessionItem('loaderState')
      setValidatedUserAPIStatus(API_STATUS.SUCCESS)
      close()
    }
  }

  const navigationHandling = () => {
    let saveRedirectionValue =
      localStorage?.getItem('routeRedirectionToCart') || ''
    if (saveRedirectionValue && router.asPath.includes('cart')) {
      saveRedirectionValue = saveRedirectionValue.replace(
        '#removemodalopen',
        ''
      )
      return saveRedirectionValue
    } else {
      return '/checkout'
    }
  }

  const handleLoginPromptGAEvent = () => {
    recordGA4Event(window, GA_EVENT.LOGIN_PROMPT, {
      previous_page: getCurrentPage(),
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    })
  }

  useEffect(() => {
    if (show) {
      handleLoginPromptGAEvent()
    }
  }, [show])

  useEffect(() => {
    setIsOTPSent(false)
  }, [show])

  useEffect(() => {
    setTimeout(() => showError(false), 5000)
  }, [isError])

  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  )

  useEffect(() => {
    if (timer > 0) {
      const otpTimer = setTimeout(timeOutCallback, 1000)
      return () => {
        clearTimeout(otpTimer)
      }
    }
  }, [timer, timeOutCallback])

  const resetTimer = function () {
    if (!timer) {
      setTimer(OTP_TIMER)
    }
  }

  if (typeof document !== 'undefined') {
    if (
      document !== undefined &&
      document.getElementById(`otp-${LOGIN_FORM_ID}`)
    ) {
      document.getElementById(`otp-${LOGIN_FORM_ID}`)?.focus()
    }

    if (
      document !== undefined &&
      document.getElementById(`mobileNumber-${LOGIN_FORM_ID}`)
    ) {
      document.getElementById(`mobileNumber-${LOGIN_FORM_ID}`)?.focus()
    }
  }

  if (typeof window === 'undefined') {
    return null
  }

  return (
    <>
      <LoginPromptDialogBox
        setIsOTPSent={setIsOTPSent}
        setTimer={setTimer}
        deviceInfo={deviceInfo}
        isOTPSent={isOTPSent}
        mobileNumber={mobileNumber}
        timer={timer}
        resendOTP={resendOTP}
        getIfUserIsNewOrOld={getIfUserIsNewOrOld}
        submitState={submitState}
        validatedUserAPIStatus={validatedUserAPIStatus}
        isError={isError}
        isUserAlreadyExist={isUserAlreadyExist}
        submitDispatch={submitDispatch}
        show={show}
      />
      <LoginPromptSuccessPopup
        appVersionData={appVersionData}
        deviceInfo={deviceInfo}
        onClose={() => setNewUserLoginPopup(false)}
        show={newUserLoginPopup}
        user={user}
      />
    </>
  )
}
export default NewLoginModal
